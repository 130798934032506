import { mapHelper } from 'common/utils'

// 按钮列表
const btnOps = [
  {
    text: '新增门店',
    value: 'addStoreBtn'
  },
  {
    text: '编辑门店',
    value: 'editStoreBtn'
  },
  {
    text: '编辑商家',
    value: 'editBusinessBtn'
  },
  {
    text: '新增成员',
    title: '新增成员',
    code: 'component',
    type: 'component',
    componentName: 'accountForm',
    value: 'addMemberBtn'
  },
]

// // 树类型列表
// const treeType = [
//   {
//     text: '平台职能部门',
//     permission: 'platformBtn',
//     value: 1
//   },
//   {
//     text: '物业管理公司',
//     permission: 'companyBtn',
//     value: 2
//   },
//   {
//     text: '社区治理单位',
//     permission: 'communityBtn',
//     value: 3
//   }
// ]
// const {
//   map: treeTypeMap,
//   setOps: treeTypeOps
// } = mapHelper.setMap(treeType)

// 性别
const sex = [
  {
    text: '男',
    value: 1
  },
  {
    text: '女',
    value: 2
  }
]
const {
  map: sexMap,
  setOps: sexOps
} = mapHelper.setMap(sex)

// 账号状态
const permission = [
  {
    text: '后台',
    value: 0
  },
  {
    text: '物管端',
    value: 1
  },
  {
    text: '业主端',
    value: 2
  }
]
const {
  map: permissionMap,
  setOps: permissionOps
} = mapHelper.setMap(permission)

// 账号状态
const status = [
  {
    text: '有效',
    value: 0
  },
  {
    text: '已过期',
    value: 1
  },
  {
    text: '已关闭',
    value: 2
  }
]
const {
  map: statusMap,
  setOps: statusOps
} = mapHelper.setMap(status)

export {
  btnOps,
  // treeTypeOps,
  // treeTypeMap,
  sexOps,
  sexMap,
  permissionOps,
  permissionMap,
  statusOps,
  statusMap
}
